import React, { useEffect, useState } from "react";
import { CalendarIcon, CheckIcon, CogIcon, CreditCardIcon, CurrencyRupeeIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card, Modal } from 'react-bootstrap';
import { useUserDropDownMutation, useGetUserBalanceMutation, useInsertTransactionMutation } from "Services/services";
import { WalletTable } from "components/Tables";
import TRANSACTIONS_DATA from "data/wallet";
import Select from 'react-select';
import users from "data/users";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default () => {
  const [transactions, setTransactions] = useState(TRANSACTIONS_DATA.map(t => ({ ...t, show: true })));
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [coordinatedCrm, setCoordinatedCrm] = useState(null);
  const [userDropDown] = useUserDropDownMutation();
  const [balance] = useGetUserBalanceMutation();
  const [transaction] = useInsertTransactionMutation();
  const [amount, setAmount] = useState(0);
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [refreshTable, setRefreshTable] = useState(false);
  const transactionTypeOptions = [
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' }
  ]
  const [transactionalType, setTransactionalType] = useState(transactionTypeOptions[0]);
  const [showEdit, setShowEdit] = useState(false)
  const [remarks, setRemarks] = useState("")
  useEffect(() => {
    fetchUserDropdown();
  }, [amount])

  const fetchUserDropdown = async () => {
    userDropDown({ user_type: "4" })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const userOptions = response.data.data.user_details.map(user => ({
            value: user.id, // Assuming `id` is the identifier you want to use
            label: `${user.user_type.toUpperCase() +" : "+ user.user_name}` // Assuming `username` is the label you want to display
          }));
          setUsers(userOptions);
        } else {
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data', error);
      });
  };




  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription.toLowerCase();
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };
  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  const [addAmount, setAddAmount] = useState('');


  const selectUserType = (selectedOption) => {

    balance({ user_id: selectedOption.value })
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          // setisVerifiedPhone(true);
          setAmount(success.data.data.totalBalance)
        } else if (success?.data?.statusCode > 300) {
          // setisVerifiedPhone(false);
          setAmount(0)

          console.error("Verification failed");
        }
      })
      .catch((error) => {
        // setisVerifiedPhone(false);
        console.error("Verification failed", error);
      });


    setUserId(selectedOption.value)
  };


  const formatAmountWithIndianCommas = (amount) => {
    const x = amount.split('.');
    let lastThree = x[0].slice(-3);
    const otherNumbers = x[0].slice(0, -3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    const result = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    return x.length > 1 ? result + '.' + x[1] : result;
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, '').replace(/₹/g, ''); // Remove existing commas and ₹ symbol
    if (!isNaN(inputValue) && inputValue.length <= 10) {
      const formattedValue = `₹${formatAmountWithIndianCommas(inputValue)}`;
      setAddAmount(formattedValue);
    }
  };

  const postData = {
    parent_id: 1,
    user_id: user?.value,
    amount: parseFloat(addAmount.replace(/[^0-9.-]+/g, "")) || 0, // Convert string to number
    credit: transactionalType?.value === 'credit', // Assuming the option value indicates credit or debit
    debit: transactionalType?.value === 'debit',
    status: "completed",
    remarks: remarks
  }
  const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
  }));
  const submit = (e) => {
    e.preventDefault();
    setShowEdit(true)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    transaction(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200 || success?.data?.statusCode === 201) {
          // setisVerifiedPhone(true);
          setShowEdit(false);
          setRemarks("")

          setUser("")
          setAddAmount("")
          setAmount(0)
          setRefreshTable(!refreshTable);
          const confirmMessage = `The amount has been ${transactionalType?.value} successfully.`;
          SwalWithBootstrapButtons.fire('Approved', confirmMessage, 'success');
          fetchUserDropdown();
        } else if (success?.data?.statusCode > 300) {
          setShowEdit(false);
          setRemarks("")
          // setisVerifiedPhone(false);
          setRefreshTable(!refreshTable);
          console.error("Verification failed");
        }
      })
      .catch((error) => {
        setShowEdit(false);
        setRemarks("")
        setRefreshTable(!refreshTable);
        // setisVerifiedPhone(false);
        console.error("Verification failed", error);
      });
  }

  const handleClose = () => {
    setShowEdit(false);
    setRemarks("")
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>MAT</Breadcrumb.Item>
            <Breadcrumb.Item active>Wallet</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Wallet</h4>
        </div>

      </div>

      <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={submit}>
        <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
          <h5 className="mb-0">Wallet Management</h5>
        </Card.Header>
        <Card.Body className="p-0 p-md-4">
          <Row>
            <Col md={6} className="mb-3 text-left">
              <Form.Group id="cardNameLabel">
                <RequiredLabel label="User" />
                <Select
                  options={users}
                  placeholder="--Select User--"
                  className="mb-0"
                  isSearchable={true}
                  value={user}
                  onChange={(selectedOption) => {
                    setUser(selectedOption);
                    selectUserType(selectedOption);
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={6} className="mb-4 align-item-right">
              <Form.Group id="cardNumberLabel">
                <Form.Label>Current Wallet Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text className="text-gray-600">
                    <CurrencyRupeeIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="0"
                    value={`${amount || 0}/-`}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3 text-left">
              <Form.Group id="transactionalTypeLabel">
                <RequiredLabel label="Transactional Type" />
                <Select
                  options={transactionTypeOptions}
                  placeholder="--Select Transactional Type--"
                  className="mb-0"
                  isSearchable={true}
                  value={transactionalType}
                  onChange={setTransactionalType}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} className="mb-4 mb-lg-0">
              <Form.Group id="cardCVCLabel">
                <RequiredLabel label="Amount" />
                <InputGroup>
                  <InputGroup.Text className="text-gray-600">
                    <CreditCardIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Ex: ₹1,000"
                    value={addAmount}
                    onChange={handleAmountChange}
                    maxLength={14} // Adjusted for ₹ symbol and commas
                  />
                </InputGroup>
              </Form.Group>
            </Col>



            <Col xs={12} className="mt-4">
              <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
                Submit
              </Button>
            </Col>

          </Row>
        </Card.Body>
      </Card>

      <WalletTable key={refreshTable} />

      <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showEdit} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Transaction Confirmation</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="remarks" className="form-label">Remarks</label>
            <textarea
              id="remarks"
              className="form-control"
              rows={3} // You can adjust the number of rows as needed
              value={remarks || ""} // Bind it to state
              onChange={(e) => setRemarks(e.target.value)} // Update the state on change
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => handleSubmit(e)}>
            Confirm
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};
