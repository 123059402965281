import React, { useState } from 'react';
import { Card, Table, Form, Button } from 'react-bootstrap';

const FeaturesManagement = () => {
    const features = [
        'Dashboard', 'Vendor onboarding', 'Reports',
        'Sender ID registration', 'Configuration',
        'Registration', 'Routing', 'Wallet',
        'Catalog & Flows', 'Channel Testing',
        'Contact List', 'Chatbot', 'Campaign',
        'N/A (Extra if any)'
    ];

    const [featurePermissions, setFeaturePermissions] = useState(
        features.reduce((acc, feature) => ({
            ...acc,
            [feature]: { read: true, edit: false, both: false }
        }), {})
    );

    const handlePermissionChange = (feature, permissionType) => {
        setFeaturePermissions(prev => ({
            ...prev,
            [feature]: {
                read: permissionType === 'read',
                edit: permissionType === 'edit',
                both: permissionType === 'both'
            }
        }));
    };

    // Function to format permissions for API payload
    const getPayload = () => {
        return Object.entries(featurePermissions).map(([feature, permissions]) => ({
            feature,
            permission: Object.keys(permissions).find(key => permissions[key])
        }));
    };

    // Function to log and send to API
    const handleSavePermissions = () => {
        const payload = getPayload();
        console.log('API Payload:', JSON.stringify(payload, null, 2));
        // Here you would typically make your API call
        // axios.post('/api/permissions', payload)...
    };

    return (
        <Card className="shadow-sm border-0">
            <Card.Body className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h5 className="mb-0">Features Management</h5>
                    <Button
                        variant="primary"
                        onClick={handleSavePermissions}
                    >
                        Save Permissions
                    </Button>
                </div>
                <Table hover responsive className="m-0">
                    <thead className="bg-light">
                        <tr>
                            <th className="text-nowrap ps-4">Feature</th>
                            <th className="text-center">Read only</th>
                            <th className="text-center">Edit</th>
                            <th className="text-center">Read & Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {features.map((feature) => (
                            <tr key={feature} className="align-middle">
                                <td className="ps-4">{feature}</td>
                                {['read', 'edit', 'both'].map((permission) => (
                                    <td
                                        key={permission}
                                        className="text-center py-3"
                                    >
                                        <Form.Check
                                            type="radio"
                                            name={`permission-${feature}`}
                                            id={`${permission}-${feature}`}
                                            checked={featurePermissions[feature][permission]}
                                            onChange={() => handlePermissionChange(feature, permission)}
                                            className="d-inline-block"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default FeaturesManagement;