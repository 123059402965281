//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Form, Button } from 'react-bootstrap';
import rcsPic from "../../assets/img/mobileScreens/RCS.png"
import { DropImagesForm } from "components/Forms";


export const RCSTemplateComponent = () => {
    const [cardCategory, setCardCategory] = useState('');
    const handleCardCategory = (e) => {
        if (e.target.value === 'Rich Card Standalone') {
            setCardCategory('Standalone');
        } else {
            setCardCategory('Carousel');
        }

    }

    return (
        <Row>
            <Col sm={6} md={8}>
                <Form className="card p-3">

                    <Row>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Template Name</Form.Label>
                            <Form.Control type="text" required placeholder="abc" maxLength={100} />
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Category</Form.Label>
                            <Form.Select onChange={handleCardCategory}>
                                <option defaultValue>Select Category</option>
                                <option>Rich Card Standalone</option>
                                <option>Rich Card Carousel</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    {cardCategory === 'Standalone' &&
                        <>
                            <Row className="mt-3">
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Card Orientation</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Vertial</option>
                                        <option>Horizontal</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Card Allignment</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Left</option>
                                        <option>Right</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mt-3">
                                <Form.Label>Add Image</Form.Label>
                                <DropImagesForm />
                            </Form.Group>
                        </>
                    }
                    {cardCategory === 'Carousel' &&
                        <>
                            <Row className="mt-3">
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Height</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Short Height</option>
                                        <option>Medium Height</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Width</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Short Width</option>
                                        <option>Medium Width</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mt-3">
                                <Form.Label>Add Card</Form.Label>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label>Add Image</Form.Label>
                                <DropImagesForm />
                            </Form.Group>
                        </>
                    }
                </Form>
                <Form className="card p-3 mt-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Header</Form.Label>
                        <Form.Control type="text" required placeholder="abc" maxLength={100} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Content Body</Form.Label>
                        <Form.Control as="textarea" rows="10" required placeholder="Enter Message Content" maxLength={1000} />
                    </Form.Group>
                </Form>
                <div className="d-flex mt-3">
                    {/* <Button variant="gray-800" type="submit" className="animate-up-2 me-2">
                        Verify
                    </Button> */}
                    <Button variant="gray-800" type="submit" className="animate-up-2">
                        Submit
                    </Button>
                </div>
            </Col>
            <Col sm={6} md={4} className="mt-5 mt-sm-0">
                <div className="img-cont">
                    <img src={rcsPic} alt="rcs preview" />
                </div>
            </Col>
        </Row>
    )
}