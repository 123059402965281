import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import "./embedded.css";

const WhatsappRegistrationModal = ({ show, handleClose }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyCapacity, setCompanyCapacity] = useState("");
    const [goLive, setGoLive] = useState("");
    // const [businessType, setBusinessType] = useState("");
    // const [industry, setIndustry] = useState("");
    // const [contactEmail, setContactEmail] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '466707449821129',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v22.0'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return () => {
            const script = document.getElementById('facebook-jssdk');
            if (script) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    const launchFacebookVerification = () => {
        if (window.FB) {
            window.FB.login(response => {
                console.log('response',response);
                if (response.authResponse) {
                    const code = response.authResponse.code;
                    console.log('Authorization code:', code);
                    console.log('Authorization response:', response);

                    handleNext();
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {
                config_id: '429971770107141',
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    setup: {},
                    featureType: '',
                    sessionInfoVersion: '3',
                }
            });
        }
    };

    const validateStep1 = () => {
        const newErrors = {};
        if (!companyWebsite.trim()) newErrors.companyWebsite = "Company Website is required.";
        if (!companyName.trim()) newErrors.companyName = "Company Name is required.";
        if (!companyCapacity) newErrors.companyCapacity = "Company Capacity is required.";
        if (!goLive) newErrors.goLive = "Go Live Timeline is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        let isValid = true;
        if (currentStep === 1) isValid = validateStep1();
        if (isValid) {
            setErrors({});
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        } else {
            handleClose();
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <h4 className="mb-4">Where do you work?</h4>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Company Website</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter website"
                                    value={companyWebsite}
                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                    isInvalid={!!errors.companyWebsite}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyWebsite}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    isInvalid={!!errors.companyName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Company Capacity?</Form.Label>
                                        <Form.Select
                                            value={companyCapacity}
                                            onChange={(e) => setCompanyCapacity(e.target.value)}
                                            isInvalid={!!errors.companyCapacity}
                                        >
                                            <option value="">Select</option>
                                            <option value="<100">&lt;100</option>
                                            <option value="100-300">100-300</option>
                                            <option value="300-500">300-500</option>
                                            <option value="500-1000">500-1000</option>
                                            <option value=">1000">&gt;1000</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.companyCapacity}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Go Live Timeline?</Form.Label>
                                        <Form.Select
                                            value={goLive}
                                            onChange={(e) => setGoLive(e.target.value)}
                                            isInvalid={!!errors.goLive}
                                        >
                                            <option value="">Select</option>
                                            <option value="immediate">Immediate</option>
                                            <option value="few weeks">Within few weeks</option>
                                            <option value="month">Within a month</option>
                                            <option value="6 months">Within 6 months</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.goLive}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </>
                );
            case 2:
                return (
                    <>
                        <h4 className="mb-4">Create your WhatsApp Business Account</h4>
                        <div className="mb-4">
                            <p className="text-muted">You'll need:</p>
                            <ul className="mb-4">
                                <li>Your company's <b>legal name and address</b></li>
                                <li>A display name and short description</li>
                                <li>A phone number you have access to, owned by your business</li>
                            </ul>
                            <div className="d-grid gap-3">
                                <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center gap-2"
                                    onClick={launchFacebookVerification}
                                >
                                    <FaFacebook />
                                    Continue with Facebook
                                </Button>
                                <Button
                                    variant="outline-secondary"
                                    onClick={handleNext}
                                >
                                    I'll do this later
                                </Button>
                            </div>
                            <div className="text-center mt-4">
                                <small className="text-muted">
                                    Don't have a phone number?{' '}
                                    <a href="#support" className="support-link">
                                        Contact Support
                                    </a>
                                </small>
                            </div>
                        </div>
                    </>
                );
            case 3:
                return (
                    <div className="text-center">
                        <h4 className="mb-4">Verify your Business Details</h4>
                        <p className="text-muted mb-4">
                            To keep WhatsApp spam free, Facebook requires you to verify your business in a few simple steps.
                        </p>

                        <div className="d-grid gap-3">
                            <Button
                                variant="primary"
                                className="d-flex align-items-center justify-content-center gap-2"
                                onClick={launchFacebookVerification}
                            >
                                <FaFacebook />
                                Verify in Facebook
                            </Button>
                            <Button
                                variant="outline-success"
                                onClick={handleNext}
                            >
                                I have completed this step
                            </Button>
                            <Button
                                variant="outline-secondary"
                                onClick={handleNext}
                            >
                                I'll do this later
                            </Button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="text-center">
                        <h4 className="mb-4 verified-header">
                            Your limited WhatsApp account is almost ready
                        </h4>
                        <p className="text-muted mb-4">
                            You can start using your WhatsApp limited account while waiting for full verification.
                        </p>

                        <div className="d-flex justify-content-center mb-4">
                            <MdVerified size={30} style={{ color: "blue" }} />
                        </div>

                        <div className="text-start mb-4">
                            <p className="text-muted">Please keep in mind:</p>
                            <ul className="text-muted">
                                <li>WhatsApp activation may take a few minutes.</li>
                                <li>
                                    You need to verify your Facebook business account in order to get full
                                    functionality on your WhatsApp.
                                    <a href="#support" className="support-link">Reach out to the provisioning team.</a>
                                </li>
                                <li>To get an official business account for WhatsApp, you need to submit a separate application. <a href="#support" className="support-link">Reach out to the provisioning team.</a></li>
                            </ul>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaWhatsapp className="text-success me-2" size={30} /> WhatsApp Number Registration
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="steps-wrapper">
                    <div className="step-container">
                        {[1, 2, 3, 4].map((step, index) => (
                            <React.Fragment key={step}>
                                <div className={`step-circle ${currentStep >= step ? 'active' : ''}`}>
                                    {step}
                                </div>
                                {index < 3 && (
                                    <div className="step-line">
                                        <div
                                            className={`line-progress ${currentStep > step ? 'active' : ''}`}
                                        ></div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {renderStepContent()}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="secondary" onClick={handleBack} className="btn-back">
                    {currentStep === 1 ? 'Close' : 'Back'}
                </Button>

                <Button
                    variant="primary"
                    className="btn-next"
                    onClick={currentStep === 4 ? handleClose : handleNext}
                >
                    {currentStep === 4 ? 'Submit' : 'Next'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WhatsappRegistrationModal;