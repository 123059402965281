import React, { useState } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb, Button, Card, Col, Dropdown, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Routes } from "routes";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { RiSettings5Line } from "react-icons/ri";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillMessage } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import HorizontalFlow from "./flowScreen";


export const ChatBotFlowScreen = () => {
    const location = useLocation();
    // const history = useHistory();

    const [chatDetails] = useState(location.state?.details || null);

    // useEffect(() => {
    //     // Ensure location.state exists before accessing details
    //     setChatDetails(location.state?.details);
    //     console.log("chatDetails:", chatDetails);

    //     if (!chatDetails) {
    //         console.log('Redirecting to ChatBot page...');
    //         history.push(Routes.ChatBot.path);
    //     } else {
    //         console.log('Chat ID found, proceeding with API call:', chatDetails.id);
    //         // API call with chatDetails.id
    //     }
    // }, [location, history]);

    const getChannelIcon = (channelName) => {
        switch (channelName) {
            case "SMS":
                return <AiFillMessage style={{ color: "blue" }} />;
            case "WhatsApp":
                return <IoLogoWhatsapp style={{ color: "#25D366" }} />;
            case "RCS":
                return <BiSolidMessageSquareDetail style={{ color: "darkorange" }} />;
            default:
                return null;
        }
    };

    const saveFlow=()=>{
        console.log('your flow is like this:::::::::::::::::::::::::');
    }

    return (
        <>
            {/* Breadcrumb and Heading */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{ className: "breadcrumb-transparent" }}
                    >
                        <Breadcrumb.Item>
                            <HomeIcon className="icon icon-xs" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>ChatBots</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={Routes.ChatBot.path}>Create ChatBots</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Create Chat-Flow</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Chat-Flow</h4>
                </div>
            </div>
            <div className="flow-main-screen">
                {/* headers */}
                <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex flex-column p-0 w-25">
                        <div className="d-flex align-items-center">
                            <span className="me-2 fs-4 d-flex align-items-center">{getChannelIcon(chatDetails?.channel)}</span>
                            <Card.Title className="me-2 mb-0 text-white text-truncate">
                                {chatDetails?.name.toUpperCase() || 'Name'}
                            </Card.Title>
                            <TbEdit className="icon icon-sm flex-shrink-0 popOut" />
                        </div>
                        <p className="m-0 truncate-twoline" style={{ color: "#c4c2c2", fontSize: "0.8rem" }}>
                            {chatDetails?.description || 'description......'}
                        </p>
                    </div>
                    <div className="d-flex">
                        <Button variant="secondary" type="submit" className="scale-up-2 me-2" onClick={saveFlow}>
                            Save
                        </Button>
                        <Dropdown className="d-flex">
                            <Dropdown.Toggle className="arrow-none">
                                <RiSettings5Line className="icon icon-xs" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start p-2">
                                <Form.Label className="text-success fw-bolder">Settings</Form.Label>
                                <Form.Group as={Col} className="mb-3 pt-1 border-top">
                                    <Form.Label>Reset Timer</Form.Label>
                                    <Form.Control type="number" required placeholder="0" />
                                    {/* <Form.Control.Feedback type="invalid">
                                Please provide a valid Code (enter a number with 4 to 10 digits).
                            </Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col}className="mb-3">
                                    <Form.Label>Back Option</Form.Label>
                                    <Form.Control type="text" required placeholder="chatbot back option" />
                                </Form.Group>
                                <Button variant="success" type="submit" className="animate-up-2">
                                    Save
                                </Button>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                {/* flow screen */}
                <HorizontalFlow />
            </div >
        </>
    );
}