
import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row, Tooltip, OverlayTrigger, Breadcrumb } from 'react-bootstrap';
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, HomeIcon, KeyIcon, SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { useCampaignSummaryMutation } from "Services/services";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
import { MdOutlineAutoMode } from "react-icons/md";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export default () => {

    //usertype
    const userData = localStorage.getItem('TOKEN');
    const userObject = JSON.parse(userData);
    const userID = userObject.id;
    const userType = userObject.user_type;

    const [seeToken, setSeeToken] = useState(false);
    const handleWatch = () => {
        setSeeToken(!seeToken);
    }
    const [token, setToken] = useState('');
    const generateToken = () => {
        const generated = Math.random().toString(36).slice(-8); // Simple password generation
        setToken(generated);
    };

    return (
        <Fragment>

            <div className="d-flex align-items-center my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item >Configurations</Breadcrumb.Item>
                        <Breadcrumb.Item active>API Token</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>API Token</h4>
                </div>
            </div>

            <Form>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>User Name</Form.Label>
                            <InputGroup>
                                <Form.Control required type="text" placeholder="Username" maxLength={20} name="user_name" />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>API Token</Form.Label>
                            <InputGroup>
                                <InputGroup.Text onClick={handleWatch}>
                                    {seeToken ? <EyeIcon width={20} />
                                        : <EyeOffIcon width={20} />}
                                </InputGroup.Text>
                                <Form.Control
                                    type={seeToken ? "text" : "password"}
                                    placeholder="token"
                                    disabled
                                    value={token}
                                />
                                <InputGroup.Text>
                                    <MdOutlineAutoMode
                                        size={20}
                                        onClick={generateToken}
                                        style={{ cursor: 'pointer' }}
                                        title="Auto-generate password"
                                    />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
};