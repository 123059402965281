import React, { useCallback, useState } from "react";
import ReactFlow, {
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Handle, // Handle might not exist in older versions; remove if necessary
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";

import { FaComment, FaExclamationTriangle, FaRedo, FaUserCircle } from "react-icons/fa";
import { VscRobot } from "react-icons/vsc";
import { MdOutlineSettings } from "react-icons/md";
import { APIModal, BotResponseModal, LoopbackModal, UserInputModal } from "components/Modals";
import { TbEdit } from "react-icons/tb";
import { XCircleIcon } from "@heroicons/react/outline";

// Custom Node Component
const CustomNode = ({ data }) => (
    <div
        style={{
            padding: '4px 8px',
            background: 'transparent',
            color: data.color,
            border: `1px solid ${data.color}`,
            borderRadius: 13,
            fontSize: 12,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: 5,
            position: "relative",
        }}
    >
        {data.icon} {data.title}

        {/* Only the Start node has a right handle */}
        {data.type === "start" ? (
            <Handle type="source" position="right" />
        ) : (data.type === "fallback" || data.type === "loopback") ? (
            <Handle type="target" position="left" />
        ) :
            (
                <>
                    <Handle type="target" position="left" />
                    <Handle type="source" position="right" />
                </>
            )}
    </div>
);

// Register Node Types
const nodeTypes = {
    start: CustomNode,
    userInput: CustomNode,
    fallback: CustomNode,
    botResponse: CustomNode,
    api: CustomNode,
    loopback: CustomNode,
};

// Define Node Properties (label, color, icon, allowed children)

const nodeProperties = {
    start: {
        type: "start",
        title: "Start",
        color: "#0084FF", // Blue
        icon: <FaComment />, // Chat icon
        children: ["userInput", "fallback"], // Can only connect to these nodes
    },
    userInput: {
        type: "userInput",
        title: "User Input",
        color: "#FF9900", // Orange
        icon: <FaUserCircle />, // User icon
        children: ["botResponse", "api", "loopback"],
    },
    fallback: {
        type: "fallback",
        title: "Fallback",
        color: "#FF0000", // Red
        icon: <FaExclamationTriangle />, // Warning icon
        children: [], // No children allowed
    },
    botResponse: {
        type: "botResponse",
        title: "Bot Response",
        color: "#33CC33", // Green
        icon: <VscRobot />, // Bot icon
        children: ["userInput"],
    },
    api: {
        type: "api",
        title: "API",
        color: "#6600CC", // Purple
        icon: <MdOutlineSettings />, // Server icon
        children: ["botResponse"],
    },
    loopback: {
        type: "loopback",
        title: "Loopback",
        color: "#666666", // Gray
        icon: <FaRedo />, // Loop icon
        children: [], // No children allowed
    },
};

const HorizontalFlow = () => {
    // initial starting node on chat initialisation
    const [nodes, setNodes, onNodesChange] = useNodesState([
        {
            id: "1",
            connectedTo: "0",
            type: "start",
            data: { ...nodeProperties["start"] },
            position: { x: 100, y: 100 },
        },
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNodeId, setselectedNodeId] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [modalType, setmodalType] = useState(null);
    // const [loopNodes, setLoopNodes] = useState({});

    // <----on forceful connect links---->
    // const onConnect = useCallback((params) => {
    //     setEdges((els) => addEdge(params, els));
    // }, []);

    // opens options
    const onNodeClick = (_, node) => {
        if (selectedNode) {
            setselectedNodeId(false);  //for toggling Menu
        } else {
            setselectedNodeId(node.id);  //also sets parent/current node for creating/editing
            setMenuPosition({
                x: window.innerWidth / 2 - 100,
                y: window.innerHeight / 2 - 20,
            });
        }

        // making options for loopback
        const botResponseNodes = nodes
            .filter(node => node.type === "botResponse" || node.type === "start")  // Filter nodes with type "botResponse"
            .map(node => ({ value: node.id, label: node.data.title }));  // Extract from node.data
        localStorage.setItem('optionsBack', JSON.stringify(botResponseNodes));
    };

    const openModal = (type) => {
        setmodalType(type);
    };

    /* Dynamic Modal Component */
    const DynamicModal = ({ modalType, isOpen, onClose, data, onSubmit }) => {
        const ModalComponents = {
            userInput: UserInputModal,
            fallback: BotResponseModal,
            botResponse: BotResponseModal,
            start: BotResponseModal,
            api: APIModal,
            loopback: LoopbackModal
        };

        const SelectedModal = ModalComponents[modalType] || null;

        return SelectedModal ? <SelectedModal isOpen={isOpen} onClose={onClose} data={data} onSubmit={onSubmit} /> : null;
    };

    const closeModal = () => {
        setmodalType(false);
        setselectedNodeId(null);
        setMenuPosition(null);
        setDataToEdit(null);
    };

    const handleFormSubmit = (newLabel) => {
        addNode(newLabel, modalType);
        closeModal();
    }

    const addNode = (newData, nodeType) => {
        if (!selectedNodeId) return;

        console.log('recieved data', newData);
        console.log('slected node id', selectedNodeId);

        setNodes((prevNodes) => {
            const existingNodeIndex = prevNodes.findIndex((node) => node.id === selectedNodeId);
            if (selectedNodeId === newData.id) {
                // Editing existing node
                console.log('yes edited existing node');

                const updatedNodes = [...prevNodes];
                updatedNodes[existingNodeIndex] = {
                    ...updatedNodes[existingNodeIndex],
                    data: {
                        ...updatedNodes[existingNodeIndex].data,
                        ...newData,
                    },
                };

                return updatedNodes;
            } else {
                // Creating a new node
                const parentNode = prevNodes.find((node) => node.id === selectedNodeId);
                if (!parentNode) return prevNodes;

                const newNodeId = (prevNodes.length + 1).toString();
                const newNode = {
                    id: newNodeId,
                    connectedTo: parentNode.id,
                    type: nodeType,
                    data: {
                        ...nodeProperties[modalType],
                        ...newData,
                    },
                    position: {
                        x: parentNode.position.x + 120,
                        y: parentNode.position.y + Math.random() * 100 - 50,
                    },
                };

                setEdges((prevEdges) => [
                    ...prevEdges,
                    { id: `e${parentNode.id}-${newNodeId}`, source: parentNode.id, target: newNodeId, type: "smoothstep", animated: true },
                ]);

                return [...prevNodes, newNode];
            }
        });

        setselectedNodeId(null);
        setMenuPosition(null);

        console.log('Updated nodes:', nodes);
    };


    const [dataToEdit, setDataToEdit] = useState(null);
    const editNode = (nodeId) => {
        const findNode = nodes.find((node) => node.id === nodeId); //taking out Node from its Id
        setDataToEdit(findNode);
        openModal(findNode.type);
    }

    const deleteNode = (nodeId) => {
        const updatedNodes = nodes.filter(node => node.id !== nodeId && node.connectedTo !== nodeId);
        setNodes(updatedNodes);
        // for menu to close
        setselectedNodeId(null);
        setMenuPosition(null);
    }

    const selectedNode = nodes.find((node) => node.id === selectedNodeId);
    const children = nodeProperties[selectedNode?.type]?.children || [];

    return (
        <div style={{ height: '92%', position: "relative", border: '1px solid #E5E7EB', borderRadius: 'inherit' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes} // Register node types
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                // onConnect={onConnect}   //for dynamic linking by user
                onNodeClick={onNodeClick}
                fitView
                attributionPosition="bottom-left"
            >
                <Controls />
                {menuPosition && selectedNodeId && (
                    <div
                        style={{
                            position: "absolute",
                            top: menuPosition.y,
                            left: menuPosition.x,
                            transform: 'translate(-50%, -50%)',
                            background: "#fff",
                            border: "1px solid #ccc",
                            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                            zIndex: 1000,
                            borderRadius: '7px'
                        }}
                    >
                        <ul className="list-group">
                            {/* Conditionally render 'Add Node' only if there are children */}
                            {children.length > 0 && (
                                <li className="list-group-item">
                                    Add Node
                                    <ul className="list-group">
                                        {children.map((type) => (
                                            <button key={type} onClick={() => openModal(type)} className="list-group-item list-group-item-action">
                                                {nodeProperties[type].icon} {nodeProperties[type].title}
                                            </button>
                                        ))}
                                    </ul>
                                </li>
                            )}
                            {/* Edit Button */}
                            <button className="list-group-item list-group-item-action text-success" onClick={() => editNode(selectedNodeId)}>
                                <TbEdit /> Edit
                            </button>
                            {/* Delete Button (Not shown for root node '1') */}
                            {selectedNodeId !== '1' && (
                                <button className="list-group-item list-group-item-action text-danger" title={'Delete with children'} onClick={() => deleteNode(selectedNodeId)}>
                                    <XCircleIcon className="icon icon-xs" /> Delete Chain
                                </button>
                            )}
                        </ul>
                    </div>
                )}
            </ReactFlow>

            {/* Node Modals for Inputs */}
            {modalType && (
                <DynamicModal
                    modalType={modalType}
                    isOpen={!!modalType}
                    onClose={closeModal}
                    data={{ type: modalType, details: dataToEdit }}
                    onSubmit={handleFormSubmit}
                />
            )}
        </div>
    );
};

export default HorizontalFlow;