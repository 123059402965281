//by shreya saraswat
import React, { Fragment, useState } from "react";
import { HomeIcon, UserIcon } from "@heroicons/react/solid";
import { Row, Form, Button, InputGroup, ToastContainer, Toast, Card, Breadcrumb, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { CurrencyRupeeIcon, KeyIcon } from "@heroicons/react/outline";
import { RoutesTable } from "components/Tables";


export default () => {

    const [showToast, setShowToast] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [toastMessage, setToastMessage] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [toastVariant, setToastVariant] = useState('success');
    // eslint-disable-next-line no-unused-vars
    const [refreshTable, setRefreshTable] = useState(false);



    // FORM PART
    const [formFields, setFormFields] = useState();
    const handleChoice = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        })
    }
    const sendRate = (e) => {
        //     e.preventDefault();

        //    const postData = {}

        //    createRoute(postData)
        //         .then((success) => {
        //             if (success?.data?.statusCode === 200) {
        //                 setToastMessage(success?.data?.message);
        //                 setToastVariant('success');
        //                 setRefreshTable(!refreshTable);
        //                 // setRefreshFileField(!refreshFileField);

        //             } else if (success?.data?.statusCode > 300) {
        //                 setToastMessage(success?.data?.message || 'Upload failed!');
        //                 setToastVariant('danger');
        //             }
        //             setShowToast(true);
        //         })
        //         .catch((error) => {
        //             setToastMessage(error?.data?.message || 'Upload failed');
        //             setToastVariant('danger');
        //             setShowToast(true);
        //         });
    }


    return (
        <Fragment>

            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <div className="d-flex align-items-start my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Route Management</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Route Management</h4>
                </div>
            </div>

            <Card as="form" border="0" className="shadow p-4 mb-4" noValidate onSubmit={sendRate}>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Message Type</Form.Label>
                        <Form.Select name="comm_type" disabled onChange={handleChoice}>
                            <option>SMS</option>
                            <option>WhatsApp</option>
                            <option>RCS</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Route Name</Form.Label>
                        <Select
                            disabled
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                            name="route_name"
                        // options={users}
                        // value={user}
                        // onChange={(selectedOption) => {
                        //     setUser(selectedOption);
                        //     selectUserType(selectedOption);
                        // }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Route Detail</Form.Label>
                        <Select
                            disabled
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                            name="details"
                        // options={users}
                        // value={user}
                        // onChange={(selectedOption) => {
                        //     setUser(selectedOption);
                        //     selectUserType(selectedOption);
                        // }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>API Method/Type</Form.Label>
                        <Select
                            disabled
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                            name="method"
                        // options={users}
                        // value={user}
                        // onChange={(selectedOption) => {
                        //     setUser(selectedOption);
                        //     selectUserType(selectedOption);
                        // }}
                        />
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="text-gray-600">
                                <UserIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                disabled
                                type="text"
                                // placeholder="username"
                                name="user_name"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="text-gray-600">
                                <KeyIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                disabled
                                type="password"
                                // placeholder="password"
                                name="password"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Key Type</Form.Label>
                        <InputGroup>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                name="key_type"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Key Value</Form.Label>
                        <InputGroup>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                name="key_value"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Cost Rate</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="text-gray-600">
                                <CurrencyRupeeIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                name="cost"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Col xs={12}>
                        <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card>

            <RoutesTable key={refreshTable} />

        </Fragment >
    );
};
