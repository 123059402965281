import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { CalendarIcon, CheckIcon, HomeIcon, KeyIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Button, InputGroup, Modal, Table, FormCheck, ToastContainer, Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import CopyToClipboard from "react-copy-to-clipboard";
import { XCircleIcon } from "@heroicons/react/outline";
import { useUserOnboardingMutation, useUserTypeMutation, useCheckUserDetailsMutation, useUserUpdateFieldMutation, useUserDropDownMutation } from "Services/services";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { CiEdit } from "react-icons/ci";
import { Toast } from 'react-bootstrap';

// import { UserOnboardingListTable } from "./Tables";
import { MdOutlineAutoMode } from "react-icons/md";
import { IoArrowBackCircle } from "react-icons/io5";
import { Routes } from "routes";
import { Link } from "react-router-dom";

export default () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [enterpriseName, setEnterpriseName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [coordinatedCrm, setCoordinatedCrm] = useState(null);
  const [coordinatedEditCrm, setCoordinatedEditCrm] = useState(null);

  const [userType, setUserType] = useState();
  const [crmValue, setCrmValue] = useState();
  const [selectUserTypeOptions, setSelectUserTypeOptions] = useState({ value: '', label: '--Select User Type--' });
  const [editDetails, setEditDetails] = useState([])
  const [selectedCrmOptions, setSelectedCrmOptions] = useState({ value: '', label: '--Select CRM--' });
  const [selectedCrmEditOptions, setSelectedCrmEditOptions] = useState([]);

  const [showEdit, setShowEdit] = useState(false)

  const [currency, setCurrency] = useState();
  const [showDefault, setShowDefault] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);

  const [copied, setCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userOnboard] = useUserOnboardingMutation();
  const [usertype] = useUserTypeMutation();
  const [crmData] = useUserDropDownMutation();
  const [userDetailUpdates] = useUserUpdateFieldMutation();
  const [verify] = useCheckUserDetailsMutation();
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);


  // Get the usertype from localStorage
  const loggedUserData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(loggedUserData);
  const loggedUserType = userObject.user_type;
  const [currencyOptions, setCurrencyOptions] = useState([]);



  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const serviceTypeOptions = [
    { value: 'transactional', label: 'Transactional' },
    { value: 'professional', label: 'Professional' },
    { value: 'any', label: 'Any' }
  ]
  const [serviceType, setServiceType] = useState();
  const [dltType, setDltType] = useState();
  const dltTypeOptions = [
    { value: 'default', label: 'Default' },
    { value: 'whitelisted', label: 'Whitelisted' },
  ]

  const [userDropDown] = useUserDropDownMutation();

  useEffect(() => {
    fetchUserDropdown();
  }, [])

  const fetchUserDropdown = async () => {
    userDropDown({ user_type: "4" })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const userOptions = response.data.data.user_details.map(user => ({
            value: user.id, // Assuming `id` is the identifier you want to use
            label: `${user.user_type.toUpperCase() + " : " + user.user_name}` // Assuming `username` is the label you want to display
          }));
          setUsers(userOptions);
        } else {
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data', error);
      });
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(
          'https://openexchangerates.org/api/currencies.json',
          {
            params: {
              app_id: 'YOUR_API_KEY', // Replace with your actual API key
            },
          }
        );
        const currencies = Object.entries(response.data).map(([key, value]) => ({
          value: key,
          label: `${key} - ${value}`,
        }));
        setCurrencyOptions(currencies);
        setCurrency(currencies[66])
      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };

    const fetchUserTypeData = async () => {
      try {
        const response = await usertype();
        if (response?.data?.statusCode === 200) {
          const allOptions = response.data.data.user_type_details.map(user => ({
            value: user.id,
            label: user.user_type
          }));

          // Filter options if logged user is a customer relationship manager
          const filteredOptions = loggedUserType === 'customer relationship manager'
            ? allOptions.filter(option => option.label === 'enterprise')
            : allOptions;

          // Prepend '--Select User Type--' at the 0th index
          const optionsWithPlaceholder = [
            { value: '', label: '--Select User Type--' },
            ...filteredOptions
          ];

          setSelectUserTypeOptions(optionsWithPlaceholder);

          // Set the default userType to the placeholder
          setUserType(optionsWithPlaceholder[0]);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };



    const fetchCrmData = async () => {
      try {
        const response = await crmData({ user_type: 3 });
        if (response?.data?.statusCode === 200) {
          const options = response.data.data.user_details.map(user => ({
            value: user.id,
            label: `${user.user_name} (${user.email})`
          }));
          // Add a default "Select CRM" option at the beginning of the options array
          setSelectedCrmOptions([{ value: '', label: '--Select CRM--' }, ...options]);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };



    fetchCurrencies();
    // fetchTableData();
    fetchUserTypeData();
    fetchCrmData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  const generatePassword = () => {
    const generatedPassword = Math.random().toString(36).slice(-8); // Simple password generation
    setPassword(generatedPassword);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const handleClose = () => {
    setShowDefault(false);
    setShowEditDefault(false);
    setShowEdit(false);
  }

  const handleCloseBack = () => {
    setShowEditDefault(false);
    setShowEdit(true);

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate userType selection
    if (!userType || userType.value === '') {
      setValidationError(true);
      return; // Stop form submission if validation fails
    }

    setShowDefault(true);
    // Continue with form submission logic
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    setShowEdit(false);
    setShowEditDefault(true);

  }

  const userData = {
    user_name: enterpriseName,
    name: name,
    phone: phone,
    email: email,
    password: password,
    expiry_date: birthday,
    customer_relation_manager: coordinatedCrm?.value,
    user_type: userType?.value,
    user_currency: currency?.value
  }

  const submitForm = (e) => {
    e.preventDefault();
    userOnboard(userData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setShowDefault(false);
          setToastMessage(success?.data?.message);
          setToastVariant('success');
          setRefreshTable(!refreshTable);
          setEnterpriseName('')
          setName('')
          setPhone('+91')
          setEmail('')
          setPassword('')
          setBirthday('')
          setCoordinatedCrm(null)
          setisVerifiedUsername('')
          setisVerifiedPhone('')
          setisVerifiedEmail('')
        } else if (success?.data?.statusCode > 300) {
          console.error('Login failed passed');
          setShowDefault(false);
          setToastMessage('Upload failed!');
          setToastVariant('danger');
          setRefreshTable(!refreshTable);
        }
        setShowToast(true);
      })
      .catch((error) => {
        setShowDefault(false);
        setRefreshTable(!refreshTable);
        console.error('Login failed', error);
        setToastMessage('Upload failed!');
        setToastVariant('danger');
        setShowToast(true);
      });
  }
  const [isPasswordEdited, setIsPasswordEdited] = useState(false);

  // Update this when the password input is edited
  const handlePasswordChange = (e) => {
    setEditDetails({ ...editDetails, password: e.target.value });
    setIsPasswordEdited(true); // Set to true when user starts editing the password
  };

  const userEditData = {
    name: editDetails.name,
    phone: editDetails.phone,
    email: editDetails.email,
    expiry_date: editDetails.expiry_date,
    customer_relation_manager: coordinatedEditCrm === null ? selectedCrmEditOptions[0]?.value : coordinatedEditCrm?.value,
    user_type: editDetails.user_type,
    user_currency: editDetails.user_currency,
    id: editDetails.id,
    ...(isPasswordEdited ? { password: editDetails.password } : {}), // Only include password if it was edited
  };


  const [passwordError, setPasswordError] = useState("");

  const submitEditForm = (e) => {


    e.preventDefault();

    // Password validation: Add your custom validation logic here
    if (editDetails.password === "" || editDetails?.password?.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return;  // Prevent form submission if the validation fails
    }

    // Clear any previous error if password is valid
    setPasswordError("");

    // Continue with API call if password validation passes
    userDetailUpdates(userEditData)
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          setShowDefault(false);
          setShowEdit(false);
          setShowEditDefault(false);
          setEditDetails([])
          setRefreshTable(!refreshTable);
          setPhone('');
        } else if (success?.data?.statusCode > 300) {
          setShowDefault(false);
          setShowEdit(false);
          setShowEditDefault(false);
          setEditDetails([])
          setRefreshTable(!refreshTable);
          setPhone('');
        }
      })
      .catch((error) => {
        setShowDefault(false);
        setShowEdit(false);
        setShowEditDefault(false);
        setEditDetails([])
        setRefreshTable(!refreshTable);
        console.error('Error updating user details', error);
      });
  };


  const selectUserType = (selectedOption) => {
    setUserType(selectedOption);
    setCrmValue(selectedOption.value)
  };

  const [isVerifiedUsername, setisVerifiedUsername] = useState();
  const [isVerifiedPhone, setisVerifiedPhone] = useState();
  const [isVerifiedEmail, setisVerifiedEmail] = useState();

  const verifyUsername = (e) => {
    const username = e.target.value;
    setEnterpriseName(username);

    const regex = /^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9_]+$/;

    if (username.length >= 3 && username.length <= 12 && regex.test(username)) {
      const postData = { data: username };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedUsername(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedUsername(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedUsername(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedUsername();
    }
  };
  const verifyEmail = (e) => {
    const email = e.target.value;
    setEmail(email);

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    if (regex.test(email)) {
      const postData = { data: email };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedEmail(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedEmail(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedEmail(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedEmail();
    }
  };

  const verifyPhone = (phone) => {
    setPhone(phone)
    const regex = /^(\+|00)?\d{1,3}[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (phone.length >= 6 && phone.length <= 13 && regex.test(phone)) {
      const postData = { data: phone };
      verify(postData)
        .then((success) => {
          if (success?.data?.statusCode === 200) {
            setisVerifiedPhone(true);
          } else if (success?.data?.statusCode > 300) {
            setisVerifiedPhone(false);
            console.error("Verification failed");
          }
        })
        .catch((error) => {
          setisVerifiedPhone(false);
          console.error("Verification failed", error);
        });
    } else {
      setisVerifiedPhone();
    }
  }

  const handleEdit = async (userDetails) => {


    try {
      const response = await crmData({ user_type: 3 });
      if (response?.data?.statusCode === 200) {
        const options = response.data.data.user_details.map(user => ({
          value: user.id,
          label: `${user.user_name} (${user.email})`
        }));

        setSelectedCrmEditOptions([
          { value: userDetails.customer_relation_manager, label: userDetails.customer_relation_manager_name },
          ...options
        ]);
      } else {
        console.error('Failed to fetch user data', response.data);
      }
    } catch (error) {
      console.error('Error fetching user data', error);
    } finally {
    }

    setEditDetails(userDetails);
    setShowEdit(true);
  };

  const [validationError, setValidationError] = useState(false);

  const handleUserTypeChange = (selectedOption) => {
    setUserType(selectedOption);
    setValidationError(false); // Clear error on valid selection
    selectUserType(selectedOption);
  };

  const [selectedRoute, setSelectedRoute] = useState("smpp");

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
  };

  const [selectedOptions, setSelectedOptions] = useState({
    spam: "",
    receiver: "",
    sender: "",
    content: "",
  });


  const handleCheckboxChange = (group, value) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [group]: prevState[group] === value ? "" : value, // Toggle selection
    }));
  };



  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>MAT</Breadcrumb.Item>
            <Breadcrumb.Item active>Add SMS Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Register SMS</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Card border="0" className="shadow mb-4">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-4">Add SMS Account</h5>
                <Button as={Link} variant="none" to={Routes.SMSRegistration.path}>
                  <IoArrowBackCircle size={30} />
                </Button>
              </div>

              <input type="text" name="preventAutoFill" style={{ display: 'none' }} />
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="userType">
                    <Form.Label>Enterprise Name</Form.Label>
                    <Select
                      options={users}
                      placeholder="--Select User--"
                      className="mb-0"
                      isSearchable={true}
                      value={user}
                      onChange={(selectedOption) => {
                        setUser(selectedOption);
                        selectUserType(selectedOption);
                      }}
                    />
                    {validationError && (
                      <div className="invalid-feedback">
                        Please select a valid user type.
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="name">
                    <Form.Label>Department Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your Department Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-name"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="username">
                    <Form.Label>Account</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter your Account Number"
                        value={enterpriseName}
                        onChange={verifyUsername}
                        pattern="^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9_]+$"
                        title="Username must be alphanumeric with at least 2 letters, special character '_' only, and no spaces."
                        maxLength={12}
                        minLength={3}
                        autoComplete="new-username"
                      />
                      {isVerifiedUsername && (
                        <InputGroup.Text>
                          <CheckIcon width={20} className="text-success" />
                        </InputGroup.Text>
                      )}
                      {isVerifiedUsername === false && (
                        <InputGroup.Text>
                          <XCircleIcon width={20} className="text-danger" />
                        </InputGroup.Text>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid username. It must contain at least 2 letters, only alphanumeric characters or '_', and no spaces.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>

                <Col md={4} className="mb-3">
                  <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <KeyIcon width={20} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        maxLength={8}
                        autoComplete="new-password"
                      />
                      <InputGroup.Text>
                        <MdOutlineAutoMode
                          size={20}
                          onClick={generatePassword}
                          style={{ cursor: 'pointer' }}
                          title="Auto-generate password"
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="userType">
                    <Form.Label>Route</Form.Label>
                    <Select
                      options={selectUserTypeOptions}
                      placeholder="--Select Route--"
                      className={`mb-0 ${validationError ? 'is-invalid' : ''}`} // Add a red border on error
                      value={userType || selectUserTypeOptions[0]}
                      onChange={handleUserTypeChange}
                    />
                    {validationError && (
                      <div className="invalid-feedback">
                        Please select a valid user type.
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="accountTypeOptions">
                    <Form.Label>Account Type</Form.Label>
                    <div className="d-flex flex-wrap justify-content-around">
                      <Form.Check
                        type="radio"
                        id="smpp"
                        label="SMPP"
                        name="accountTypeOptions"
                        value="smpp"
                        className="me-3"
                        onChange={handleRouteChange}
                        checked={selectedRoute === "smpp"}
                      />
                      <Form.Check
                        type="radio"
                        id="web"
                        label="WEB"
                        name="accountTypeOptions"
                        value="web"
                        className="me-3"
                        onChange={handleRouteChange}
                        checked={selectedRoute === "web"}
                      />
                      <Form.Check
                        type="radio"
                        id="http"
                        label="HTTP"
                        name="accountTypeOptions"
                        value="http"
                        onChange={handleRouteChange}
                        checked={selectedRoute === "http"}
                      />
                      <Form.Check
                        type="radio"
                        id="whatsapp"
                        label="WHATSAPP"
                        name="accountTypeOptions"
                        value="whatsapp"
                        onChange={handleRouteChange}
                        checked={selectedRoute === "whatsapp"}
                      />
                      <Form.Check
                        type="radio"
                        id="rcs"
                        label="RCS"
                        name="accountTypeOptions"
                        value="rcs"
                        onChange={handleRouteChange}
                        checked={selectedRoute === "rcs"}
                      />
                    </div>
                    {validationError && (
                      <div className="invalid-feedback d-block">
                        Please select a valid route.
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-3">
                  <Form.Group id="session">
                    <Form.Label>Session (TX/RX/TRX)</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Session"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-session"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="tps">
                    <Form.Label>TPS</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter TPS"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-tps"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="api">
                    <Form.Label>Allow API</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter API"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-api"
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-3">
                  <Form.Group id="dltTypeLabel">
                    <RequiredLabel label="DLT Type" />
                    <Select
                      options={dltTypeOptions}
                      placeholder="--Select DLT Type--"
                      className="mb-0"
                      isSearchable={true}
                      value={dltType}
                      onChange={setDltType}
                    />
                  </Form.Group>
                </Col>

              </Row>
            </Card.Body>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-4">Commercial</h5>
              </div>
              <input type="text" name="preventAutoFill" style={{ display: 'none' }} />
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="cost">
                    <Form.Label>Cost</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your cost"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-cost"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="dltCost">
                    <Form.Label>DLT Cost</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter DLT Cost"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-dltCost"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="refund">
                    <Form.Label>Refund</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Refund Amount"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="new-refund"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="serviceTypeLabel">
                    <RequiredLabel label="Service Type" />
                    <Select
                      options={serviceTypeOptions}
                      placeholder="--Select Service Type--"
                      className="mb-0"
                      isSearchable={true}
                      value={serviceType}
                      onChange={setServiceType}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3 mt-3">
                  <Form.Group id="accountTypeOptions">
                    <div className="d-flex justify-content-between">
                      <div className="me-3">
                        <Form.Label className="d-block mb-2">SPAM</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="spamGlobal"
                          label="Global"
                          name="spamOptions"
                          value="global"
                          checked={selectedOptions.spam === "global"}
                          className="mb-1"
                          onChange={() => handleCheckboxChange("spam", "global")}
                        />
                        <Form.Check
                          type="checkbox"
                          id="spamUser"
                          label="User"
                          name="spamOptions"
                          value="user"
                          checked={selectedOptions.spam === "user"}
                          onChange={() => handleCheckboxChange("spam", "user")}
                        />
                      </div>
                      <div className="me-3">
                        <Form.Label className="d-block mb-2">Receiver</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="receiverGlobal"
                          label="Global"
                          name="receiverOptions"
                          value="global"
                          checked={selectedOptions.receiver === "global"}
                          className="mb-1"
                          onChange={() => handleCheckboxChange("receiver", "global")}
                        />
                        <Form.Check
                          type="checkbox"
                          id="receiverUser"
                          label="User"
                          name="receiverOptions"
                          value="user"
                          checked={selectedOptions.receiver === "user"}
                          onChange={() => handleCheckboxChange("receiver", "user")}
                        />
                      </div>
                      <div className="me-3">
                        <Form.Label className="d-block mb-2">Sender ID</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="senderGlobal"
                          label="Global"
                          name="senderOptions"
                          value="global"
                          checked={selectedOptions.sender === "global"}
                          className="mb-1"
                          onChange={() => handleCheckboxChange("sender", "global")}
                        />
                        <Form.Check
                          type="checkbox"
                          id="senderUser"
                          label="User"
                          name="senderOptions"
                          value="user"
                          checked={selectedOptions.sender === "user"}
                          onChange={() => handleCheckboxChange("sender", "user")}
                        />
                      </div>
                      <div className="me-3">
                        <Form.Label className="d-block mb-2">Content ID</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="contentGlobal"
                          label="Global"
                          name="contentOptions"
                          value="global"
                          checked={selectedOptions.content === "global"}
                          className="mb-1"
                          onChange={() => handleCheckboxChange("content", "global")}
                        />
                        <Form.Check
                          type="checkbox"
                          id="contentUser"
                          label="User"
                          name="contentOptions"
                          value="user"
                          checked={selectedOptions.content === "user"}
                          onChange={() => handleCheckboxChange("content", "user")}
                        />
                      </div>
                    </div>
                    {validationError && (
                      <div className="invalid-feedback d-block">
                        Please select valid options.
                      </div>
                    )}
                  </Form.Group>
                </Col>

              </Row>
              <Button variant="primary" className="my-3" type="submit">Preview</Button>
            </Card.Body>
          </Card>
        </Form>
      </div >


      <div className="mt-3">
        <Modal backdrop="static" keyboard={false} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="h6">User Detail Preview</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-2 mt-2">
              <Row>
                <Col md={10}>
                  <Table responsive className="table-centered table-nowrap rounded mb-0">
                    <tbody>
                      <tr>
                        <td className="border-0">Enterprise Name</td>
                        <td className="border-0">{name}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Department Name</td>
                        <td className="border-0">{enterpriseName}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Account</td>
                        <td className="border-0">{phone}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Password</td>
                        <td className="border-0">{password}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Route</td>
                        <td className="border-0">{password}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Account Type</td>
                        <td className="border-0">{birthday ? moment(birthday).format("DD/MM/YYYY") : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Session</td>
                        <td className="border-0">{userType?.label}</td>
                      </tr>
                      <tr>
                        <td className="border-0">TPS</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Allow API</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">DLT Type</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="border-0 text-center font-weight-bold">COMMERCIAL</td>
                      </tr>
                      <tr>
                        <td className="border-0">Cost</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">DLT Cost</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Refund</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                      <tr>
                        <td className="border-0">Service Type</td>
                        <td className="border-0">{currency ? currency.label : ""}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={2} className="d-flex align-items-start">
                  <Card.Body className="d-flex flex-column">
                    <div>
                      {copied ? (
                        <Button size="sm" variant="success" className="copy-code-button">
                          Copied
                        </Button>
                      ) : (
                        <CopyToClipboard
                          text={`Name: ${name}\nUsername: ${enterpriseName}\nPhone Number: ${phone}\nEmail: ${email}\nPassword: ${password}\nExpiry Date: ${birthday ? moment(birthday).format("DD/MM/YYYY") : ""}\n${crmValue === '2' ? `Coordinated CRM: ${coordinatedCrm ? coordinatedCrm.label : ""}\n` : ""}User Type: ${userType ? userType.label : ""}\nUser Currency: ${currency ? currency.label : ""}`}
                          onCopy={handleCopy}
                        >
                          <Button size="sm" variant="primary" className="copy-code-button">
                            Copy
                          </Button>
                        </CopyToClipboard>
                      )}
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>

            <Card className="mb-2 mt-3">
              <Row>
                <Col md={12}>
                  <Card.Body>
                    <b>Share Details:</b>
                    <FormCheck type="switch" className="mt-2">
                      <FormCheck.Input
                        type="checkbox"
                        id="emailSwitch"
                        checked={selectedOption === 'email'}
                        onChange={() => setSelectedOption('email')}
                      />
                      <FormCheck.Label htmlFor="emailSwitch" style={{ marginLeft: '8px' }}>
                        Email ID
                      </FormCheck.Label>
                    </FormCheck>
                    <FormCheck type="switch" className="mt-2">
                      <FormCheck.Input
                        type="checkbox"
                        id="phoneSwitch"
                        checked={selectedOption === 'phone'}
                        onChange={() => setSelectedOption('phone')}
                      />
                      <FormCheck.Label htmlFor="phoneSwitch" style={{ marginLeft: '8px' }}>
                        Phone Number
                      </FormCheck.Label>
                    </FormCheck>
                    <FormCheck type="switch" className="mt-2">
                      <FormCheck.Input
                        type="checkbox"
                        id="bothSwitch"
                        checked={selectedOption === 'both'}
                        onChange={() => setSelectedOption('both')}
                      />
                      <FormCheck.Label htmlFor="bothSwitch" style={{ marginLeft: '8px' }}>
                        Both
                      </FormCheck.Label>
                    </FormCheck>
                    <FormCheck type="switch" className="mt-2">
                      <FormCheck.Input
                        type="checkbox"
                        id="noneSwitch"
                        checked={selectedOption === ''}
                        onChange={() => setSelectedOption('')}
                      />
                      <FormCheck.Label htmlFor="noneSwitch" style={{ marginLeft: '8px' }}>
                        None
                      </FormCheck.Label>
                    </FormCheck>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={submitForm}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <UserOnboardingListTable key={refreshTable} handleEdit={handleEdit} />  */}
    </>
  );
};
