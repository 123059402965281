
import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row, Breadcrumb } from 'react-bootstrap';
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, HomeIcon, SearchIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { useCDRDetailsMutation } from "Services/services";
import { Link } from "react-router-dom";


export default () => {

  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;

  const [tableData, setTableData] = useState([]);

  // const [bulkOption, setBulkOption] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');


  const [viewCDR] = useCDRDetailsMutation();


  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await viewCDR({
          page: currentPage,
          limit: pagelimit,
          campaign_id: searchValue,
          startDate: startDate,
          endDate: endDate,
          mode: status
        });

        if (response?.data?.statusCode === 200) {
        
          if (response?.data?.data?.result?.data) {
            // Update state with fetched records
            setTableData(response?.data?.data?.result?.data);
            setTotalPages(response?.data?.data?.result?.totalPages || 0);
            setTotalPosts(response?.data?.data?.result?.totalRecords || 0);
          } else {
            setTableData([]);
            setTotalPages(0);
            setTotalPosts(0);
          }
        } else {
          setTableData([]);
        }
      } catch (error) {
        alert(error.message);
        setTableData([]);
      }
    };

    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);


  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TableRow = (props) => {
    const variant = props.mode === "whatsapp" ? "success"
      : props.mode === "sms" ? "purple"
        : props.mode === "rcs" ? "warning" : "primary";
    return (
      <tr className="border-bottom">
        <td><span className="fw-bold text-info">{props.message_id}</span></td>
        <td><span className="fw-normal">{props.campaign_id}</span></td>
        <td><span className="fw-normal">{props.campaign_name}</span></td>
        <td><span className="fw-normal">{moment(props.campaign_start_at).format('DD/MM/YYYY')}</span></td>
        <td><span className="fw-normal">{props.message_time}</span></td>
        {userType !== 'enterprise' && <td><span className="fw-normal">{props.created_by}</span></td>}
        <td><span className="fw-normal">{props.template_id}</span></td>
        <td><span className="fw-normal">{props.components}</span></td>
        <td><span className="fw-normal">{props.content}</span></td>
        <td><span className="fw-normal">{props.peid}</span></td>
        <td><span className="fw-normal">{props.language}</span></td>
        <td><span className="fw-normal">{props.template_category}</span></td>
        <td><span className="fw-normal">{props.template_support}</span></td>
        <td><span className={`fw-normal text-capitalize text-${variant}`}>{props.mode}</span></td>
        <td><span className="fw-normal">{props.msisdn}</span></td>
        <td><span className="fw-normal">{props.status}</span></td>
        <td><span className="fw-normal">{props.code}</span></td>
        <td><span className="fw-normal">{props.remark}</span></td>
        <td><span className="fw-normal">{props.cost}</span></td>
        <td><span className="fw-normal">{props.sell_price}</span></td>
        <td><span className="fw-normal">{moment(props.createdAt).format('DD/MM/YYYY')}</span></td>
        <td><span className="fw-normal">{moment(props.updatedAt).format('DD/MM/YYYY')}</span></td>
      </tr>
    );
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center my-3">
        <div className="d-block">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item >Reports</Breadcrumb.Item>
            <Breadcrumb.Item active>CDR Reports</Breadcrumb.Item>
          </Breadcrumb>
          <h4>CDR Reports</h4>
          {/* <p className="mb-0">Your CDR analytics dashboard.</p> */}
        </div>
      </div>

      <Row className="mt-3">
        <Col xs={12} xl={12}>
          <div className="table-settings">
            <Row className="justify-content-between align-items-center">
              <div className="d-flex flex-wrap">
                <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                  <InputGroup.Text>
                    <SearchIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    placeholder="Campaign Name/Enterprise"
                    value={searchValue}
                    onChange={changeSearchValue}
                  />
                </InputGroup>
                {/* <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                  <option value="" defaultValue>All Channels</option>
                  <option value="sms">SMS</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="rcs">RCS</option>
                </Form.Select> */}
                <Form.Group id="DateRange">
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text>
                      <CalendarIcon className="icon icon-xs" />
                    </InputGroup.Text>
                    <Datetime
                      timeFormat={false}
                      onChange={setStart}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="Start Date"
                          value={startDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderRadius: "0" }}
                          onChange={(e) => setStart(e.target.value)} />
                      )} />
                    <Datetime
                      timeFormat={false}
                      onChange={setEnd}
                      isValidDate={currDate => currDate.isAfter(start)}
                      initialViewDate={end}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="End Date"
                          value={endDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                          onChange={(e) => setEnd(e.target.value)} />
                      )} />
                  </InputGroup>
                </Form.Group>

                <Dropdown className="ms-auto mb-3">
                  <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                    <CogIcon className="icon icon-sm" />
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                    <span className="small ps-3 fw-bold text-dark">Show</span>
                    {[10, 20, 30].map((limit) => (
                      <Dropdown.Item
                        key={limit}
                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                         onClick={() => {
                          setPagelimit(limit); // Update the page limit
                          setCurrentPage(1);  // Reset the page number to 1
                        }}
                      >
                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>
          </div>

          <Card border="0" className="table-wrapper table-responsive shadow">

            <Card.Body>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th className="border-bottom">Message ID</th>
                    <th className="border-bottom">Campaign ID</th>
                    <th className="border-bottom">Campaign Name</th>
                    <th className="border-bottom">Campaign Start Date</th>
                    <th className="border-bottom">Message Time</th>
                    {userType !== 'enterprise' && <th className="border-bottom">Created By</th>}
                    <th className="border-bottom">Template ID</th>
                    <th className="border-bottom">Components</th>
                    <th className="border-bottom">Content</th>
                    <th className="border-bottom">Enterprise ID</th>
                    <th className="border-bottom">Language</th>
                    <th className="border-bottom">Template Category</th>
                    <th className="border-bottom">Template Support</th>
                    <th className="border-bottom">Mode</th>
                    <th className="border-bottom">MSISDN</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Code</th>
                    <th className="border-bottom">Remarks</th>
                    <th className="border-bottom">Cost</th>
                    <th className="border-bottom">Sell Price</th>
                    <th className="border-bottom">Created At</th>
                    <th className="border-bottom">Updated At</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {tableData.length > 0 ? tableData.map((u, index) => (
                    <TableRow key={index + 1} index={index + 1} {...u} />
                  )) :
                    <tr>
                      <td colSpan="8" className="text-center text-danger">No Data Found</td>
                    </tr>}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                <Nav>
                  <Pagination className="mb-0">
                    <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                      Previous
                    </Pagination.Prev>
                    {currentPage >= 3 && (
                      <>
                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                        <Pagination.Ellipsis />
                      </>
                    )}
                    {currentPage > 1 && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                        {currentPage - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    {currentPage < totalPages && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                        {currentPage + 1}
                      </Pagination.Item>
                    )}
                    {currentPage < totalPages - 1 && (
                      <>
                        <Pagination.Ellipsis />
                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                          {totalPages}
                        </Pagination.Item>
                      </>
                    )}
                    <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                      Next
                    </Pagination.Next>
                  </Pagination>

                </Nav>
                <small className="fw-normal small mt-2 mt-md-0">
                  Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                </small>
              </Card.Footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};