
import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row, Breadcrumb, OverlayTrigger, Tooltip, Tab } from 'react-bootstrap';
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, HomeIcon, SearchIcon, ViewListIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { useCDRDetailsMutation } from "Services/services";
import { Link } from "react-router-dom";


export default () => {
  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;


  const [viewCDR] = useCDRDetailsMutation();

  useEffect(() => {
    // const fetchTableData = async () => {
    //   try {
    //     const response = await viewCDR({
    //     });

    //     if (response?.data?.statusCode === 200) {
    //       // Filter out non-numeric keys (like "statusCode", "totalRecords", etc.)
    //       const records = Object.keys(response?.data?.data)
    //         .filter((key) => !isNaN(key)) // Retain only numeric keys
    //         .map((key) => response?.data?.data[key]); // Map the values for these keys

    //       if (records.length > 0) {
    //         // Update state with fetched records
    //         setTableData(records);
    //         setTotalPages(response?.data?.data?.totalPages || 0);
    //         setTotalPosts(response?.data?.data?.totalRecords || 0);
    //       } else {
    //         console.warn('No data available.');
    //         setTableData([]);
    //         setTotalPages(0);
    //         setTotalPosts(0);
    //       }
    //     } else {
    //       setTableData([]);
    //     }
    //   } catch (error) {
    //     alert(error.message);
    //     setTableData([]);
    //   }
    // };

    // fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="d-flex align-items-center my-3">
        <div className="d-block">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Configurations</Breadcrumb.Item>
            <Breadcrumb.Item active>API Docs</Breadcrumb.Item>
          </Breadcrumb>
          <h4>API Docs</h4>
          {/* <p className="mb-0">Your CDR analytics dashboard.</p> */}
        </div>
      </div>

      <Tab.Container defaultActiveKey="SMS">
        <Row>
          <Col lg={2}>
            <Nav fill variant="pills" className="flex-lg-column vertical-tab">
              <Nav.Item>
                <Nav.Link eventKey="SMS" className="mb-md-3">
                  SMS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Whatsapp" className="mb-md-3">
                  WhatsApp
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="RCS" className="mb-md-3">
                  RCS
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={10}>
            <Tab.Content>
              <Tab.Pane eventKey="SMS" className="card p-4">
                sms
              </Tab.Pane>

              <Tab.Pane eventKey="Whatsapp" className="card p-4">
                whatsapp
              </Tab.Pane>

              <Tab.Pane eventKey="RCS" className="card p-4">
                coming soon !
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment >
  );
};