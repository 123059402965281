import React from "react";
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { GeneralInfoForm } from "components/SMSRegistration";
import { HomeIcon } from "@heroicons/react/solid";


export default () => {

  return (
    <>
      <div className="d-flex align-items-center my-3">
        <div className="d-block">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: " breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Registration</Breadcrumb.Item>
            <Breadcrumb.Item active>Add SMS Account</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Add SMS Account</h4>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
