import React, { useState } from "react";
import { HomeIcon, PlusIcon } from "@heroicons/react/solid";
import { Breadcrumb, Card, Modal, Form, Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TbEdit, TbTrash } from "react-icons/tb";
import { IoPauseCircleOutline, IoPlay } from "react-icons/io5";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillMessage } from "react-icons/ai";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { Routes } from "routes";
import { useHistory } from "react-router-dom";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [channelOptions] = useState([
    // { value: "SMS", label: "SMS", icon: <AiFillMessage /> },
    { value: "WhatsApp", label: "WhatsApp", icon: <IoLogoWhatsapp /> },
    // { value: "RCS", label: "RCS", icon: <BiSolidMessageSquareDetail /> },
  ]);
  const [channel, setChannel] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cards, setCards] = useState([]);
  const [errors, setErrors] = useState({});
  // const [editingCardId, setEditingCardId] = useState(null); // Track card being edited
  const [cardStates, setCardStates] = useState({});
  const [isOnline, setIsOnline] = useState(true);

  const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  }));

  const handleAddChatbot = () => {
    setShowModal(true);
    // setEditingCardId(null); // Reset editing card state

    // temporary
    const newCard = {
      id: 1,
      channel: 'WhatsApp',
      name: 'Chatbot flow',
      description: 'description...',
    };
    history.push({
      pathname: Routes.ChatFlowScreen.path,
      state: { details: newCard }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    let formErrors = {};

    if (!channel) {
      formErrors.channel = "Channel is required";
    }

    if (!name) {
      formErrors.name = "Name is required";
    }

    if (!description) {
      formErrors.description = "Description is required";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Prevent form submission if validation fails
    }

    // Adding a new card
    const newCard = {
      id: cards.length + 1,
      channel: channel?.label,
      name: name,
      description: description,
    };
    setCards([...cards, newCard]);
    setShowModal(false);
    setChannel(null);
    setName("");
    setDescription("");
    setErrors({});
    // send to flow screen -directly
    // handleEdit(newCard.id);     //if no id passed => i.e creating new flow, if id is passed => editing old card
  };

  const handleDelete = (id) => {
    setCards(cards.filter((card) => card.id !== id));
    SwalWithBootstrapButtons.fire(
      "Deleted",
      "Chatbot deleted successfully!",
      "success"
    );
  };

  const handlePlay = (id) => {
    setCardStates((prev) => ({ ...prev, [id]: "playing" }));
  };

  const handlePause = (id) => {
    setCardStates((prev) => ({ ...prev, [id]: "paused" }));
  };

  const history = useHistory();
  const handleEdit = (id) => {
    const cardToEdit = cards.find((card) => card.id === id);
    // setChannel(channelOptions.find((option) => option.label === cardToEdit.channel));
    // setName(cardToEdit.name);
    // setDescription(cardToEdit.description);
    // setEditingCardId(id); // Set card id being edited

    // setCardDetails(cardToEdit);
    history.push({
      pathname: Routes.ChatFlowScreen.path,
      state: { details: cardToEdit }
    });
  };

  // Clear error when user starts typing or selects an option
  const handleInputChange = (e, field) => {
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
    if (field === "name") {
      setName(e.target.value);
    } else if (field === "description") {
      setDescription(e.target.value);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setErrors((prevErrors) => ({ ...prevErrors, channel: "" }));
      setChannel(selectedOption);
    }
  };

  const getChannelIcon = (channelName) => {
    switch (channelName) {
      case "SMS":
        return <AiFillMessage style={{ color: "blue" }} />;
      case "WhatsApp":
        return <IoLogoWhatsapp style={{ color: "#25D366" }} />;
      case "RCS":
        return <BiSolidMessageSquareDetail style={{ color: "darkorange" }} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Breadcrumb and Heading */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>ChatBot</Breadcrumb.Item>
            <Breadcrumb.Item active>Create ChatBots</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Create ChatBots</h4>
        </div>
      </div>

      {/* Create Chatbot Button and Cards  */}
      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
        {/* Add Chatbot Button */}
        <button
          className="d-flex align-items-center justify-content-center text-white rounded-lg shadow"
          style={{
            width: "150px",
            height: "150px",
            backgroundColor: "#09090B",
            fontSize: "14px",
          }}
          onClick={handleAddChatbot}
        >
          <PlusIcon className="me-2" style={{ width: "20px", height: "20px" }} />
          Add Chatbot
        </button>


        {/* Chatbot Cards */}
        <div className="d-flex flex-wrap gap-3">
          {cards.map((card) => (
            <Card
              key={card.id}
              className="shadow"
              style={{
                width: "200px",
                height: "150px",
                position: "relative",
                padding: "10px",
                textAlign: "left",
              }}
            >

              {/* Card Content */}
              <Card.Body className="d-flex flex-column p-0">
                <div className="d-flex align-items-center border-bottom pb-1">
                  <span className="me-2 fs-4 d-flex align-items-center">{getChannelIcon(card.channel)}</span>
                  <Card.Title className="mb-0 text-truncate">
                    {card.name.toUpperCase()}
                  </Card.Title>
                </div>
                <div className="mt-2">
                  <Card.Subtitle>
                    Description:
                  </Card.Subtitle>
                  <Card.Text style={{ fontSize: "12px", marginTop: "2px", marginBottom: "0px" }} className="truncate-twoline">
                    {card.description}
                  </Card.Text>
                </div>

                {/* Edit, Delete, Play, and Pause Buttons */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Edit Button */}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Edit</Tooltip>}
                  >
                    <Card.Link onClick={() => handleEdit(card.id)}>
                      <TbEdit className="icon icon-wide text-info" />
                    </Card.Link>
                  </OverlayTrigger>

                  {/* Delete Button */}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Delete</Tooltip>}
                  >
                    <Card.Link onClick={() => handleDelete(card.id)}>
                      <TbTrash className="icon icon-wide text-danger" />
                    </Card.Link>
                  </OverlayTrigger>

                  {/* Play or Pause Button */}
                  {cardStates[card.id] === "paused" ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="m-0">Play</Tooltip>}
                    >
                      <Card.Link onClick={() => handlePlay(card.id)}>
                        <IoPlay className="icon icon-wide text-success" />
                      </Card.Link>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="m-0">Pause</Tooltip>}
                    >
                      <Card.Link onClick={() => handlePause(card.id)}>
                        <IoPauseCircleOutline className="icon icon-wide text-warning" />
                      </Card.Link>
                    </OverlayTrigger>
                  )}

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="m-0">Online</Tooltip>}
                  >
                    <Card.Link onClick={() => handleEdit(card.id)}>
                      <HiOutlineStatusOnline className="icon icon-wide text-success" />
                    </Card.Link>
                  </OverlayTrigger>
                </div>

              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      {/* Modal for Add/Edit Chatbot */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Chatbot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Channel Select with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Select Channel</Form.Label>
              <Select
                options={channelOptions}
                value={channel}
                onChange={handleSelectChange}
                placeholder="Choose channel"
              />
              {errors.channel && <div className="text-danger">{errors.channel}</div>}
            </Form.Group>

            {/* Name Input with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </InputGroup>
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </Form.Group>

            {/* Description Textarea with Validation Error */}
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={description}
                onChange={(e) => handleInputChange(e, "description")}
              />
              {errors.description && <div className="text-danger">{errors.description}</div>}
            </Form.Group>

            <Button type="submit" className="w-100">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
