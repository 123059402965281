export const createWhatsappTempMutation = (builder) => ({
    createWhatsappTemp: builder.mutation({
      query: (credentials) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
        return {
          url: '/whatsapp_template/KARIX',
          method: 'POST',
          body: credentials,
          headers: {
            'Authorization': token
          },
        }
      },
    }),
  });