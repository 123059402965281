import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { FaExclamationCircle, FaTachometerAlt, FaWhatsapp } from "react-icons/fa";
import { FaClock, FaEnvelope, FaFacebook, FaMoneyBill } from "react-icons/fa6";
import { MdOutlineMessage, MdVerified } from "react-icons/md";
import "./embedded.css";
import { IoIosCash, IoIosMail, IoIosSpeedometer, IoIosTimer } from "react-icons/io";
import { CiMoneyBill } from "react-icons/ci";
import Chart from 'react-apexcharts';

const WhatsappMigrationModal = ({ show, handleClose }) => {
    const [currentStep, setCurrentStep] = useState(0); // Start with step 0
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyCapacity, setCompanyCapacity] = useState("");
    const [goLive, setGoLive] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [industry, setIndustry] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState(0);
    const [chartOptions, setChartOptions] = useState({ /* ... chart options ... */ });
    const [tasks, setTasks] = useState([ // State for tasks and their completion
        { id: 1, name: "SYNC", completed: false },
        { id: 2, name: "SYSTEM USER", completed: false },
        { id: 3, name: "CREDIT LINE", completed: false },
        { id: 4, name: "WEBHOOK", completed: false },
        { id: 5, name: "SYNC PHONE NUMBERS", completed: false },
        { id: 6, name: "SYNC TEMPLATES", completed: false },
    ]);
    const [estimatedTime, setEstimatedTime] = useState(2); // Initial estimated time in minutes
    const [timeElapsed, setTimeElapsed] = useState(0); // Time elapsed in seconds
    const [isSetupComplete, setIsSetupComplete] = useState(false); // Track setup completion


    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '466707449821129',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v22.0'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return () => {
            const script = document.getElementById('facebook-jssdk');
            if (script) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    useEffect(() => {
        let timer;
        if (currentStep === 5 && progress < 100) {
            timer = setInterval(() => {
                setTimeElapsed(timeElapsed + 1); // Increment time elapsed
                const newProgress = Math.min(Math.floor((timeElapsed / (2 * 60)) * 100), 100); // Calculate progress based on 2 minutes
                setProgress(newProgress);

                const completedTasksCount = Math.floor(newProgress / (100 / tasks.length));
                const updatedTasks = tasks.map((task, index) => ({
                    ...task,
                    completed: index < completedTasksCount,
                }));
                setTasks(updatedTasks);
                setEstimatedTime(2 - (timeElapsed / 60)); // Update estimated time

                if (newProgress === 100) {
                    clearInterval(timer); // Stop timer when 100% is reached
                }
            }, 1000); // Update every 1 second
            if (progress === 100) {
                setIsSetupComplete(true); // Set to true when setup is complete
            }
        }
        return () => clearInterval(timer); // Clear timer on unmount or step change
        
    }, [currentStep, progress, tasks.length, timeElapsed]);


    const launchFacebookVerification = () => {
        if (window.FB) {
            window.FB.login(response => {
                if (response.authResponse) {
                    const code = response.authResponse.code;
                    console.log('Authorization code:', code);
                    handleNext();
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {
                config_id: '429971770107141',
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    setup: {},
                    featureType: '',
                    sessionInfoVersion: '3',
                }
            });
        }
    };

    const validateStep1 = () => {
        const newErrors = {};
        if (!companyWebsite.trim()) newErrors.companyWebsite = "Company Website is required.";
        if (!companyName.trim()) newErrors.companyName = "Company Name is required.";
        if (!companyCapacity) newErrors.companyCapacity = "Company Capacity is required.";
        if (!goLive) newErrors.goLive = "Go Live Timeline is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        let isValid = true;
        if (currentStep === 2) isValid = validateStep1();
        if (isValid) {
            setErrors({});
            setCurrentStep(currentStep + 1);
        }
        if (currentStep < 5) {
            setCurrentStep(currentStep + 1);
            setTimeElapsed(0); // Reset time elapsed for the next step
            setProgress(0); // Reset progress
            setTasks(tasks.map(task => ({ ...task, completed: false }))); // Reset tasks
        } else if (currentStep === 5 && isSetupComplete) { // Only allow submit if setup is complete
            handleClose();
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            setTimeElapsed(0); // Reset time elapsed
            setProgress(0); // Reset progress
            setTasks(tasks.map(task => ({ ...task, completed: false }))); // Reset tasks
            setEstimatedTime(2)
            setIsSetupComplete(false); // Reset setup completion status when going back
        } else {
            handleClose();
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className="migration-info p-4">
                        <h4 className="mb-4">Migrating Phone Numbers Between WhatsApp Business Accounts via Embedded Signup</h4>
                        <div className="text-start">
                            <h5>Migration Information</h5>
                            <p>In practice, migration means that a business can keep the same phone number if:</p>
                            <ul>
                                <li>They are using the API with one of our BSPs and want to switch to a different provider.</li>
                                <li>They are using their own implementation and want to switch to a BSP.</li>
                            </ul>

                            <h5>How Migration Works</h5>
                            <ul className="migration-details">
                                <li className="d-flex mb-3">
                                    <FaClock className="me-2" size={22} />
                                    <div>
                                        <strong>Downtime:</strong><br /> Until migration is completed by registering the phone number on the destination WABA, the source WABA can continue to send and receive messages without disruption in service. After migration is complete, the destination WABA can start sending messages immediately, without any downtime.
                                    </div>
                                </li>
                                <li className="d-flex">
                                    <IoIosMail className="me-2" size={50} style={{
                                        position: "relative",
                                        bottom: "12px"
                                    }} />
                                    <div>
                                        <strong>Message Template Migration:</strong><br />
                                        <p>A phone number’s high-quality message templates are maintained throughout the migration process — in practice, they are copied to the destination WABA. These templates do not need to go through review again and can be sent immediately. Copying message templates from source WABA may cause the total number of templates in the destination WABA to go over the limit. This means that new templates cannot be added without deleting existing templates.</p>
                                        <p>Low-quality, rejected, or pending templates are not migrated. Any existing message templates in the destination WABA will not be overwritten.</p>
                                    </div>
                                </li>
                                <li className="d-flex mb-3">
                                    <IoIosCash className="me-2" size={28} style={{
                                        position: "relative",
                                        bottom: "2px"
                                    }} />
                                    <div>
                                        <strong>Billing Migration:</strong><br /> Messages sent before migration are charged to the source business. Messages sent after migration are charged to the destination business. Messages sent from the source, and are not delivered before migration, are still charged to the source business when they get delivered.
                                    </div>
                                </li>
                                <li className="d-flex mb-3">
                                    <FaTachometerAlt className="me-2" size={18} style={{
                                        position: "relative",
                                        top: "2px"
                                    }} />
                                    <div>
                                        <strong>Rate Limits:</strong> <br />
                                        Standard Graph API rate limits apply to the migration.
                                    </div>
                                </li>
                                <li className="mb-3 d-flex">
                                    <FaExclamationCircle className="me-2" size={20} style={{
                                        position: "relative",
                                        top: "2px"
                                    }} />
                                    <div>
                                        <strong>Exceptions:</strong>
                                        <ul>
                                            <li>If you are using your phone number with the WhatsApp Business App, you need to delete your phone number from the app to use the same number on the API. In this case, you do not need to use migration. See Migrate App Phone Number to API for specific steps.</li>
                                            <li>Businesses working with a BSP cannot switch to a direct integration using phone migration.</li>
                                            <li>Message and chat history are not migrated with this process.</li>
                                            <li>You cannot migrate several phone numbers at once, but APIs can be called for each number separately.</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <>
                        <h4 className="mb-4">Get started with WhatsApp's Business API</h4>
                        <p className="text-muted">WhatsApp is one of the most popular communication applications in the world. WHatsApp is a Facebook company and requires a</p>
                        <p>Facebook account and Facebook Business Manager to set up.</p>

                        <div className="mb-4">
                            <h5 className="mb-4">What you will Need</h5>
                            <ul className="mb-4">
                                <li>Access to Facebook Business Manager</li>
                                <li>A viable Phone Number for WhatsApp</li>
                                <li>Your business's legal address and details</li>
                                <li>Verify your Facebook Business</li>
                            </ul>
                        </div>


                        <div className="mb-4">
                            <ol className="mb-4">
                                <li>Please ensure you have full access to the WhatsApp Business account</li>
                                <li>Please ensure the ID that is being used belongs to the business that you manage</li>
                                <li>Please ensure the FB user ID has access to manage the WhatsApp account templates, phone numbers, generate QR code</li>
                            </ol>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <h4 className="mb-4">Where do you work?</h4>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Company Website</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter website"
                                    value={companyWebsite}
                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                    isInvalid={!!errors.companyWebsite}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyWebsite}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    isInvalid={!!errors.companyName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Company Capacity?</Form.Label>
                                        <Form.Select
                                            value={companyCapacity}
                                            onChange={(e) => setCompanyCapacity(e.target.value)}
                                            isInvalid={!!errors.companyCapacity}
                                        >
                                            <option value="">Select</option>
                                            <option value="<100">&lt;100</option>
                                            <option value="100-300">100-300</option>
                                            <option value="300-500">300-500</option>
                                            <option value="500-1000">500-1000</option>
                                            <option value=">1000">&gt;1000</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.companyCapacity}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Go Live Timeline?</Form.Label>
                                        <Form.Select
                                            value={goLive}
                                            onChange={(e) => setGoLive(e.target.value)}
                                            isInvalid={!!errors.goLive}
                                        >
                                            <option value="">Select</option>
                                            <option value="immediate">Immediate</option>
                                            <option value="few weeks">Within few weeks</option>
                                            <option value="month">Within a month</option>
                                            <option value="6 months">Within 6 months</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.goLive}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </>
                );
            case 3:
                return (
                    <>
                        <h4 className="mb-4">Create your WhatsApp Business Account</h4>
                        <div className="mb-4">
                            <p className="text-muted">You'll need:</p>
                            <ul className="mb-4">
                                <li>Your company's <b>legal name and address</b></li>
                                <li>A display name and short description</li>
                                <li>A phone number you have access to, owned by your business</li>
                            </ul>
                            <div className="d-grid gap-3">
                                <Button
                                    variant="primary"
                                    className="d-flex align-items-center justify-content-center gap-2"
                                    onClick={launchFacebookVerification}
                                >
                                    <FaFacebook />
                                    Continue with Facebook
                                </Button>
                                <Button
                                    variant="outline-secondary"
                                    onClick={handleNext}
                                >
                                    I'll do this later
                                </Button>
                            </div>
                            <div className="text-center mt-4">
                                <small className="text-muted">
                                    Don't have a phone number?{' '}
                                    <a href="#support" className="support-link">
                                        Contact Support
                                    </a>
                                </small>
                            </div>
                        </div>
                    </>
                    // <div className="text-center">
                    //     <h4 className="mb-4">Verify your Business Details</h4>
                    //     <p className="text-muted mb-4">
                    //         To keep WhatsApp spam free, Facebook requires you to verify your business in a few simple steps.
                    //     </p>

                    //     <div className="d-grid gap-3">
                    //         <Button
                    //             variant="primary"
                    //             className="d-flex align-items-center justify-content-center gap-2"
                    //             onClick={launchFacebookVerification}
                    //         >
                    //             <FaFacebook />
                    //             Verify in Facebook
                    //         </Button>
                    //         <Button
                    //             variant="outline-success"
                    //             onClick={handleNext}
                    //         >
                    //             I have completed this step
                    //         </Button>
                    //         <Button
                    //             variant="outline-secondary"
                    //             onClick={handleNext}
                    //         >
                    //             I'll do this later
                    //         </Button>
                    //     </div>
                    // </div>
                );
            case 4:
                return (
                    <div className="">
                        <h4 className="mb-4">Verify your Business Details</h4>
                        <p className="text-muted mb-4">
                            To keep WhatsApp spam free, Facebook requires you to verify your business in a few simple steps.
                        </p>
                        <div className="mb-4">
                            <ol className="mb-4">
                                <li>Go to <b>Business Info</b> in the Facebook Business Manager</li>
                                <li>Fill in your company details</li>
                                <li>Then go to <b>Security Centre</b> and verify your business</li>
                            </ol>
                        </div>
                        <div className="d-grid gap-3">
                            <Button
                                variant="outline-success"
                                onClick={handleNext}
                            >
                                I have completed this step
                            </Button>
                            <Button
                                variant="outline-secondary"
                                onClick={handleNext}
                            >
                                I'll do this later
                            </Button>
                        </div>
                    </div>
                );
                case 5:
                    return (
                        <div className="text-center">
                            <h4 className="mb-4">Your Account is being Setup</h4>
                            <div id="chart">
                                <Chart options={chartOptions} series={[progress]} type="radialBar" height="350" />
                            </div>
                            <div className="mt-4">
                                {tasks.map(task => (
                                    <div key={task.id} className="d-flex align-items-center mb-2">
                                        <div className="task-icon">
                                            {task.completed ? <MdVerified className="text-success" /> : <IoIosTimer />}
                                        </div>
                                        <span className={`task-name ${task.completed ? 'completed' : ''}`}>{task.name}</span>
                                    </div>
                                ))}
                            </div>
                            <p className="mt-4">
                                {progress < 100
                                    ? `Your estimated wait time is ${Math.ceil(estimatedTime)} minutes` // Round up estimated time
                                    : "Setup Complete!"}
                            </p>
                        </div>
                    );
            default:
                return null;
        }
    };
    const handleCloseModal = () => {
        if (isSetupComplete) {
            handleClose(); // Only close if setup is complete
        } else if (currentStep !== 5) {
            handleClose();
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName={currentStep === 0 ? "custom-modal-wide" : "custom-modal"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaWhatsapp className="text-success me-2" size={30} /> WhatsApp Number Migration
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="steps-wrapper">
                    <div className="step-container">
                        {[0, 1, 2, 3, 4, 5].map((step, index) => (
                            <React.Fragment key={step}>
                                <div className={`step-circle ${currentStep >= step ? 'active' : ''}`}>
                                    {step === 0 ? 'Info' : step}
                                </div>
                                {index < 5 && (
                                    <div className="step-line">
                                        <div
                                            className={`line-progress ${currentStep > step ? 'active' : ''}`}
                                        ></div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {renderStepContent()}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="secondary" onClick={handleBack} className="btn-back">
                    {currentStep === 0 ? 'Close' : 'Back'}
                </Button>

                <Button
                    variant="primary"
                    className="btn-next"
                    onClick={currentStep === 5 ? handleClose : handleNext}
                >
                    {currentStep === 0 ? 'Start Migration' : currentStep === 5 ? 'Submit' : 'Next'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WhatsappMigrationModal;